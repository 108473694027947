var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "mailBox"
  }, [_c("p", [_vm._v(" 您的邮箱:"), _c("em", [_vm._v(_vm._s(_vm.mail))]), _vm._v("正在取消诺辉健康的新闻订阅！")]), _c("p", [_vm._v(" Your email:"), _c("em", [_vm._v(_vm._s(_vm.mail))]), _vm._v(" is canceling New Horizon Health news subscription! ")]), _c("button", {
    staticClass: "unsubscribe",
    on: {
      click: _vm.unsubscribe
    }
  }, [_vm._v("确认/Enter")])])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };