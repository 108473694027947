import { mapState } from 'vuex';
export default {
  name: "unsubscribe",

  data() {
    return {
      mail: ''
    };
  },

  computed: { ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {
    this.getData();
  },

  methods: {
    unsubscribe() {
      this.$http.post(this.$api.email_ExitSubscribeMail, {
        email: this.$route.query.mail
      }).then(res => {
        this.$message.success('取消成功！Cancellation succeeded！');
      });
    },

    getData() {
      this.mail = this.$route.query.mail;
    }

  }
};